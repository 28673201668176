import React, { useState } from "react";
import { Field, Form, Formik } from "formik";
import { connect } from "react-redux";
import {
  Button,
  Card,
  InputGroup,
  Form as BootstrapForm,
  Container,
} from "react-bootstrap";
import * as Yup from "yup";
import styles from "./AddLinkForm.module.css";
import { useNavigate } from "react-router-dom";
import { updateFeature, createFeature } from "../../actions/Feature.action";

const AddLinkForm = ({ createFeature, data, edit, updateFeature }) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const onSubmitHandeler = async (values) => {
    setIsLoading(true);
    let check = edit
      ? await updateFeature(values, data.id)
      : await createFeature(values);
    if (check === true) {
      setTimeout(() => {
        setIsLoading(false);
        navigate("/admin/links");
      }, 1000);
    } else {
      setIsLoading(false);
    }
  };

  let initVals = {
    name: edit === true && data ? data.name : "",
    description: edit === true && data ? data.description : "",
    url: edit === true && data ? data.url : "",
    active: edit === true && data ? data.active : true,
  };

  const SignupSchema = Yup.object().shape({
    name: Yup.string().required("Name is required!"),
    description: Yup.string().required("Description is required!"),
    url: Yup.string().required("URL is required!"),
  });
  return (
    <Container>
      <Card bg="white" text="dark" className={`crd shadow`}>
        <Card.Body>
          <h1 className="fs-4 fw-normal py-3">
            Fill the form to {edit ? "edit" : "add"} link
          </h1>
          <Formik
            initialValues={initVals}
            validationSchema={SignupSchema}
            enableReinitialize
            onSubmit={(values) => onSubmitHandeler(values)}
          >
            {({ errors, touched, values, setFieldValue }) => (
              <Form>
                <InputGroup className="mb-3 d-flex flex-column">
                  <div className="d-flex justify-content-between align-items-center pb-2">
                    <label htmlFor="name" className="d-block">
                      Name
                    </label>
                    {errors.name && touched.name ? (
                      <small className="text-danger pt-2">{errors.name}</small>
                    ) : null}
                  </div>
                  <Field
                    as={BootstrapForm.Control}
                    placeholder="Type name of the link..."
                    name="name"
                    isValid={!errors.name && touched.name}
                    type="text"
                    className={`${styles.input} w-100`}
                    isInvalid={errors.name && touched.name}
                  />
                </InputGroup>
                <InputGroup className="mb-3 d-flex flex-column">
                  <div className="d-flex justify-content-between align-items-center pb-2">
                    <label htmlFor="description" className="d-block">
                      Description
                    </label>
                    {errors.description && touched.description ? (
                      <small className="text-danger pt-2">
                        {errors.description}
                      </small>
                    ) : null}
                  </div>
                  <Field
                    as={BootstrapForm.Control}
                    placeholder="Type description of the link..."
                    name="description"
                    isValid={!errors.description && touched.description}
                    type="text"
                    className={`${styles.input} w-100`}
                    isInvalid={errors.description && touched.description}
                  />
                </InputGroup>
                <InputGroup className="mb-3 d-flex flex-column">
                  <div className="d-flex justify-content-between align-items-center pb-2">
                    <label htmlFor="url" className="d-block">
                      URL
                    </label>
                    {errors.url && touched.url ? (
                      <small className="text-danger pt-2">{errors.url}</small>
                    ) : null}
                  </div>
                  <Field
                    as={BootstrapForm.Control}
                    placeholder="Type url of the link..."
                    name="url"
                    isValid={!errors.url && touched.url}
                    type="text"
                    className={`${styles.input} w-100`}
                    isInvalid={errors.url && touched.url}
                  />
                </InputGroup>
                <InputGroup className="mb-3 d-flex flex-column">
                  <div className="d-flex justify-content-between align-items-center pb-2">
                    <label htmlFor="active" className="d-block">
                      Active
                    </label>
                  </div>
                  <Field
                    as={BootstrapForm.Switch}
                    checked={values.active}
                    onChange={(e) =>
                      e ? setFieldValue("active", e.target.checked) : null
                    }
                  />
                </InputGroup>

                <div className="pt-4">
                  <Button
                    variant="primary"
                    type="submit"
                    className="btn_primary"
                    disabled={isLoading}
                  >
                    {isLoading ? "Loading..." : edit ? "Edit Link" : "Add Link"}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default connect(null, { createFeature, updateFeature })(AddLinkForm);
