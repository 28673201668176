import React, { Fragment, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Table,
} from "react-bootstrap";
import { connect } from "react-redux";
import { createMark, getMark } from "../../actions/Mark.action";
import { months, years } from "../../constants/MonthsAndYears";
import { remarkArray } from "../../constants/remarksArray";

import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import styles from "./AddMarkForm.module.css";
import { getSubName } from "../../utils/getSubName";
import StudentView from "./StudentView";

const AddMarkForm = ({ getMark, grades, createMark }) => {
  const [grade, setGrade] = useState("");
  const [batch, setBatch] = useState("");
  const [shift, setShift] = useState("");
  const [uid, setUid] = useState("");
  const [remark, setRemark] = useState(remarkArray[0].name);
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState("0");
  const [loading, setLoading] = useState(false);

  const [subjects, setSubjects] = useState(null);
  const ref = useRef(null);

  const [response, setResponse] = useState(null);

  const selectHandeler = async () => {
    const list = await getMark(batch, year, month, grade, shift, uid);
    if (list !== false) {
      if (list.success) {
        setSubjects([
          ...list.marks
            .sort((a, b) => a.subjectId - b.subjectId)
            .map((l) => ({
              id: l.subjectId,
              subjectId: l.subjectId,
              grade: l.grade,
            })),
        ]);
      } else {
        grades.map((item) => {
          if (item.id === parseInt(grade)) {
            setSubjects(
              item.subjects.map((l) => ({
                id: l.id,
                subjectId: l.id,
                grade: "A",
              }))
            );
          }
        });
      }

      if (list.overall) {
        setRemark(list.overall);
      }
      setResponse(list);
    } else {
      grades.map((item) => {
        if (item.id === parseInt(grade)) {
          setSubjects(
            item.subjects.map((l) => ({
              id: l.id,
              subjectId: l.id,
              grade: "A",
            }))
          );
        }
      });
    }
  };

  const submitHandeler = async () => {
    setLoading(true);

    let check = await createMark(
      month,
      year,
      batch,
      subjects,
      grade,
      shift,
      uid,
      remark
    );
    if (check === true) {
      setLoading(false);
      const list = await getMark(batch, year, month, grade, shift, uid);
      if (list !== false) {
        setSubjects([
          ...list.marks
            .sort((a, b) => a.subjectId - b.subjectId)
            .map((l) => ({
              id: l.subjectId,
              subjectId: l.subjectId,
              grade: l.grade,
            })),
        ]);
        setRemark(list.overall);
        setResponse(list);
      }
    } else {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Card bg="white" text="dark" className={`crd shadow`}>
        <Card.Body>
          <Row>
            <Col md={3} className="py-3">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <label htmlFor="grade" className="d-block">
                  Class
                </label>
              </div>
              <Form.Select
                onChange={(e) => {
                  setBatch("");
                  setShift("");
                  setGrade(e.target.value);
                }}
              >
                <option value={""}>Select Class</option>
                {grades &&
                  grades.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </Form.Select>
            </Col>
            {grade !== "" ? (
              <Col md={3} className="py-3">
                <div className="d-flex justify-content-between align-items-center pb-2">
                  <label htmlFor="shift" className="d-block">
                    Shift
                  </label>
                </div>
                <Form.Select
                  onChange={(e) => {
                    setBatch("");
                    setShift(e.target.value);
                  }}
                >
                  <option value={""}>Select Shift</option>
                  {grades
                    .filter((grd) => grd.id === parseInt(grade))[0]
                    .shifts.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            ) : (
              <></>
            )}
            {grade !== "" && shift !== "" ? (
              <Col md={3} className="py-3">
                <div className="d-flex justify-content-between align-items-center pb-2">
                  <label htmlFor="batch" className="d-block">
                    Batch
                  </label>
                </div>
                <Form.Select onChange={(e) => setBatch(e.target.value)}>
                  <option value={""}>Select Batch</option>
                  {grades
                    .filter((grd) => grd.id === parseInt(grade))[0]
                    .batches.filter((bt) => bt.shiftId === parseInt(shift))
                    .map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            ) : (
              <></>
            )}

            {grade !== "" && shift !== "" && batch !== "" && uid !== "" ? (
              <Col
                md={3}
                className="d-flex jsutify-content-end align-items-end py-3"
              >
                <Button
                  onClick={selectHandeler}
                  variant="primary"
                  type="submit"
                  className="btn_primary"
                >
                  Select
                </Button>
              </Col>
            ) : (
              <></>
            )}
          </Row>
          <Row>
            <Col md={3} className="py-3">
              <InputGroup className="mb-3 d-flex flex-column">
                <div className="d-flex justify-content-between align-items-center pb-2">
                  <label htmlFor="uid" className="d-block">
                    Student Serial
                  </label>
                </div>
                <Form.Control
                  placeholder="Student Serial..."
                  name="uid"
                  type="number"
                  required
                  className={`${styles.input} w-100`}
                  onChange={(e) => setUid(e.target.value)}
                />
              </InputGroup>
            </Col>

            <Col md={4} className="py-3">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <label htmlFor="month" className="d-block">
                  Month
                </label>
              </div>
              <Form.Select
                onChange={(e) => {
                  setMonth(e.target.value);
                }}
                id="month"
                value={month}
                name="month"
              >
                {months.map((item, i) => (
                  <option key={i} value={`${i}`}>
                    {item}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col md={4} className="py-3">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <label htmlFor="year" className="d-block">
                  Year
                </label>
              </div>
              <Form.Select
                value={year}
                onChange={(e) => {
                  setYear(e.target.value);
                }}
                id="year"
                name="year"
              >
                {years.map((item, i) => (
                  <option key={i} value={`${item}`}>
                    {item}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {response && response.student ? (
        <Card bg="white" text="dark" className={`crd shadow mt-4`}>
          <Card.Body>
            <h1 className="fs-4 fw-normal py-3">Fill the student evaluation</h1>
            <div className="p-4" ref={ref}>
              {response && response.student ? (
                <div className={" pt-4 pb-3"}>
                  <div className="">
                    <StudentView
                      data={response}
                      months={months}
                      response={response}
                    />
                  </div>
                </div>
              ) : (
                <></>
              )}

              {subjects ? (
                <Table striped bordered hover size="sm">
                  <thead>
                    <tr>
                      <th className="text-center">#</th>
                      <th className="">Learning Matters</th>
                      <th className="text-center">Grade</th>
                    </tr>
                  </thead>
                  <tbody>
                    {subjects
                      .sort(
                        (a, b) => parseInt(a.subjectId) - parseInt(b.subjectId)
                      )
                      .map((student, i) => (
                        <tr key={i}>
                          <td className="text-center">{i + 1}</td>
                          <td className="">{getSubName(grades, student.id)}</td>
                          <td className="text-center">
                            <div className={styles.preview}>
                              <Form.Select
                                value={student.grade}
                                onChange={(e) =>
                                  setSubjects([
                                    ...subjects.map((std) =>
                                      student.id === std.id
                                        ? { ...std, grade: e.target.value }
                                        : std
                                    ),
                                  ])
                                }
                              >
                                <option value={""}>Select Grade</option>

                                <option value={"A"}>A</option>
                                <option value={"B"}>B</option>
                                <option value={"C"}>C</option>
                                <option value={"D"}>D</option>
                              </Form.Select>
                            </div>
                            <div className={styles.print}>{student.grade}</div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              ) : (
                <></>
              )}
              <Row className="pb-4">
                <Col md={12}>
                  <div className={`text-center f-bold ${styles.print}`}>
                    Overall Assessment :{" "}
                    {remarkArray.filter((item) => item.name === remark).length >
                    0
                      ? remarkArray.filter((item) => item.name === remark)[0]
                          .text
                      : "N/A"}
                  </div>
                  <div className={styles.preview}>
                    <div className="d-flex justify-content-between align-items-center pb-2">
                      <label htmlFor="remark" className="d-block">
                        Overall Assessment
                      </label>
                    </div>
                    <Form.Select
                      onChange={(e) => {
                        setRemark(e.target.value);
                      }}
                      value={remark}
                    >
                      {remarkArray.map((item, i) => (
                        <option key={i} value={item.name}>
                          {item.text}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                </Col>
              </Row>
            </div>

            <div className="d-flex justify-content-between align-iteems-center ">
              <Button
                onClick={submitHandeler}
                variant="primary"
                className="btn_primary"
                disabled={loading}
              >
                {loading ? "Loading..." : "Submit"}
              </Button>
              <div className="">
                <ReactToPrint
                  documentTitle={`Assessment`}
                  content={() => ref.current}
                >
                  <PrintContextConsumer>
                    {({ handlePrint }) =>
                      response !== null ? (
                        <Button
                          onClick={handlePrint}
                          variant="primary"
                          className="btn_primary"
                        >
                          Print
                        </Button>
                      ) : (
                        <>Please Save first</>
                      )
                    }
                  </PrintContextConsumer>
                </ReactToPrint>
              </div>
            </div>
          </Card.Body>
        </Card>
      ) : (
        <></>
      )}
    </Container>
  );
};

export default connect(null, { createMark, getMark })(AddMarkForm);
