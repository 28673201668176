import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import { connect } from "react-redux";
import {
  Button,
  Card,
  InputGroup,
  Form as BootstrapForm,
  Container,
} from "react-bootstrap";
import * as Yup from "yup";
import styles from "./AddSubjectForm.module.css";
import { useNavigate } from "react-router-dom";
import { getGradeList } from "../../actions/Grade.action";
import { toast } from "react-toastify";
import { createSubject, updateSubject } from "../../actions/Subject.action";

const AddSubjectForm = ({
  createSubject,
  data,
  edit,
  updateSubject,
  getGradeList,
  grades,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (grades === null) {
      getGradeList();
    }
  }, []);
  const onSubmitHandeler = async (values) => {
    if (values.gradeId === "none") {
      toast.error("Select a Grade!");
      return false;
    }
    setIsLoading(true);
    let check = edit
      ? await updateSubject(values, data.id)
      : await createSubject(values);
    if (check === true) {
      setTimeout(() => {
        setIsLoading(false);
        navigate("/admin/subject");
      }, 1000);
    } else {
      setIsLoading(false);
    }
  };

  let initVals = {
    name: edit === true && data ? data.name : "",
    mark: edit === true && data ? data.mark : 100,
    gradeId: edit === true && data ? data.gradeId : "none",
  };

  const SignupSchema = Yup.object().shape({
    name: Yup.string().required("Subject Name is required!"),
    gradeId: Yup.string().required("Class is required!"),
  });
  return (
    <Container>
      <Card bg="white" text="dark" className={`crd shadow`}>
        <Card.Body>
          <h1 className="fs-4 fw-normal py-3">
            Fill the form to {edit ? "edit" : "add"} a new subject
          </h1>
          <Formik
            initialValues={initVals}
            validationSchema={SignupSchema}
            enableReinitialize
            onSubmit={(values) => onSubmitHandeler(values)}
          >
            {({ errors, touched, values, setFieldValue }) => (
              <Form>
                <InputGroup className="mb-3 d-flex flex-column">
                  <div className="d-flex justify-content-between align-items-center pb-2">
                    <label htmlFor="name" className="d-block">
                      Name
                    </label>
                    {errors.name && touched.name ? (
                      <small className="text-danger pt-2">{errors.name}</small>
                    ) : null}
                  </div>
                  <Field
                    as={BootstrapForm.Control}
                    placeholder="Type name of the subject..."
                    name="name"
                    isValid={!errors.name && touched.name}
                    type="text"
                    className={`${styles.input} w-100`}
                    isInvalid={errors.name && touched.name}
                  />
                </InputGroup>

                <InputGroup className="mb-3 d-flex flex-column">
                  <div className="d-flex justify-content-between align-items-center pb-2">
                    <label htmlFor="gradeId" className="d-block">
                      Class
                    </label>
                    {errors.gradeId && touched.gradeId ? (
                      <small className="text-danger pt-2">
                        {errors.gradeId}
                      </small>
                    ) : null}
                  </div>
                  {grades ? (
                    <BootstrapForm.Select
                      gradeId="gradeId"
                      isValid={!errors.gradeId && touched.gradeId}
                      className={`${styles.input} w-100`}
                      isInvalid={errors.gradeId && touched.gradeId}
                      onChange={(e) => setFieldValue("gradeId", e.target.value)}
                      value={values.gradeId}
                    >
                      <option value="none"> Select Class</option>
                      {grades.map((grd) => (
                        <option value={grd.id} key={grd.id}>
                          {grd.name}
                        </option>
                      ))}
                    </BootstrapForm.Select>
                  ) : (
                    <></>
                  )}
                </InputGroup>

                <div className="pt-4">
                  <Button
                    variant="primary"
                    type="submit"
                    className="btn_primary"
                    disabled={isLoading}
                  >
                    {isLoading
                      ? "Loading..."
                      : edit
                      ? "Edit Subject"
                      : "Add Subject"}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  grades: state.grade.grade,
});

export default connect(mapStateToProps, {
  createSubject,
  updateSubject,
  getGradeList,
})(AddSubjectForm);
