import React from "react";
import { Container, Table } from "react-bootstrap";
import styles from "./StudentView.module.css";
import Moment from "react-moment";
import SectionHeading from "../SectionHeading/SectionHeading";
import { BASE_URL } from "../../constants/URL";
import logo from "../../Assets/logo.jpeg";

const StudentView = ({ data, months, response }) => {
  return (
    <Container fluid className="py-0">
      <div className="position-relative">
        {data?.student?.image ? (
          <>
            <img src={logo} alt="logo" className={styles.logo} />
            <div
              className={styles.logo2}
              style={{
                background: `url(${BASE_URL}/api/uploads/${data?.student?.image})`,
                backgroundSize: "cover",
                backgroundPosition: "center center",
              }}
            ></div>
          </>
        ) : (
          <></>
        )}
        <SectionHeading
          noLogo={data?.student?.image}
          title={``}
          subtitle={``}
          grade={data?.student?.gradeId}
          shift={data?.student?.shiftId}
          batch={data?.student?.batchId}
        />
        <Table bordered size="sm">
          <tbody>
            <tr>
              {data && data.student && data.student.name && (
                <>
                  <td>
                    <span className="fw-bold">Name</span>
                  </td>
                  <td>{data?.student.name}</td>
                </>
              )}

              {data && data.student && data.student.phone && (
                <>
                  <td>
                    <span className="fw-bold">Phone</span>
                  </td>
                  <td>{data?.student.phone}</td>
                </>
              )}
            </tr>
            <tr>
              {data && data.student && data.student.father && (
                <>
                  <td>
                    <span className="fw-bold">Father</span>
                  </td>
                  <td>{data?.student.father}</td>
                </>
              )}
              {data && data.student && data.student.mother && (
                <>
                  <td>
                    <span className="fw-bold">Mother</span>
                  </td>
                  <td>{data?.student.mother}</td>
                </>
              )}
            </tr>
            <tr>
              {data && data.student && data.student.dob && (
                <>
                  <td>
                    <span className="fw-bold">Date of Birth</span>
                  </td>
                  <td>
                    <Moment format="DD/MM/YYYY">{data?.student.dob}</Moment>
                  </td>
                </>
              )}
              {data && data.student && data.student.blood && (
                <>
                  <td>
                    <span className="fw-bold">Blood Group</span>
                  </td>
                  <td>{data?.student.blood}</td>
                </>
              )}
            </tr>
            <tr>
              {data && data.student && data.student.address && (
                <>
                  <td>
                    <span className="fw-bold">Address</span>
                  </td>
                  <td colSpan={3}>{data?.student.address}</td>
                </>
              )}
            </tr>
          </tbody>
        </Table>
        {/* <div className={styles.std_info}>
          <div className={styles.bordr_right}>
            <div>
              <div className="d-flex justify-content-center align-items-center ">
                <span className={styles.info_heading}>Name : </span>

                <span className={styles.info_text + " ms-1"}>
                  {data?.student.name}
                </span>
              </div>
            </div>
            {data?.student.address ? (
              <div>
                <div className="d-flex justify-content-center align-items-center ">
                  <span className={styles.info_heading}>Address : </span>

                  <span className={styles.info_text + " ms-1"}>
                    {data?.student.address}
                  </span>
                </div>
              </div>
            ) : (
              <></>
            )}

            {data?.student.blood ? (
              <div>
                <div className="d-flex justify-content-center align-items-center ">
                  <span className={styles.info_heading}>Blood Group : </span>

                  <span className={styles.info_text + " ms-1"}>
                    {data?.student.blood}
                  </span>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className={styles.bordr_right}>
            {data?.student.father ? (
              <div>
                <div className="d-flex justify-content-center align-items-center ">
                  <span className={styles.info_heading}>Father : </span>

                  <span className={styles.info_text + " ms-1"}>
                    {data?.student.father}
                  </span>
                </div>
              </div>
            ) : (
              <></>
            )}

            {data?.student.dob ? (
              <div>
                <div className="d-flex justify-content-center align-items-center ">
                  <span className={styles.info_heading}>Date of Birth : </span>

                  <span className={styles.info_text + " ms-1"}>
                    <Moment format="DD/MM/YYYY">{data?.student.dob}</Moment>
                  </span>
                </div>
              </div>
            ) : (
              <></>
            )}

          </div>

          <div className={styles.bordr_right}>
            {data?.student.mother ? (
              <div>
                <div className="d-flex justify-content-center align-items-center ">
                  <span className={styles.info_heading}>Mother : </span>

                  <span className={styles.info_text + " ms-1"}>
                    {data?.student.mother}
                  </span>
                </div>
              </div>
            ) : (
              <></>
            )}
            {data?.student.phone ? (
              <div>
                <div className="d-flex justify-content-center align-items-center ">
                  <span className={styles.info_heading}>Phone : </span>

                  <span className={styles.info_text + " ms-1"}>
                    {" "}
                    {data?.student.phone}
                  </span>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div> */}
        <span className="d-block text-center fw-bold pt-4">
          {response && response.month === 3
            ? "1st Terminal Assessment"
            : response && response.month === 7
            ? "2nd Terminal Assessment"
            : response && response.month === 11
            ? "Final Assessment"
            : "Monthly Assesment"}
        </span>
        <span className="d-block fw-bold text-center">
          {months[response.month]} {response.year}
        </span>{" "}
        <span className="d-block fs-6 text-center">
          A = Excellent <span className="me-2"></span> B = Good
          <span className="me-2"></span> C = Average
          <span className="me-2"></span> D = Did not attempt
        </span>
      </div>
    </Container>
  );
};

export default StudentView;
