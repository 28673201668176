import axios from "axios";
import { toast } from "react-toastify";
import {
  CREATE_FEATURE,
  DELETE_FEATURE,
  GET_FEATURE,
  UPDATE_FEATURE,
} from "../constants/Type";
import { BASE_URL } from "../constants/URL";

//GET FEATURE LIST
export const getFeatureList = () => async (dispatch) => {
  // console.log("HITT");
  try {
    const res = await axios.get(`${BASE_URL}/api/feature`);
    // console.log(res);

    dispatch({
      type: GET_FEATURE,
      payload: res.data.data,
    });
  } catch (err) {
    toast.error(err.response.data.message);
  }
};

// CREATE FEATURE
export const createFeature = (values) => async (dispatch) => {
  let formData = {
    name: values.name,
    description: values.description,
    url: values.url,
    active: values.active,
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    // TODO ::: API CALL
    const res = await axios.post(
      `${BASE_URL}/api/feature`,
      JSON.stringify(formData),
      config
    );
    dispatch({
      type: CREATE_FEATURE,
    });
    dispatch(getFeatureList());
    toast.success("Link added successfully");
    return true;
  } catch (err) {
    toast.error(err.response.data.message);
    return false;
  }
};

// UPDATE FEATURE
export const updateFeature = (values, id) => async (dispatch) => {
  let formData = {
    name: values.name,
    url: values.url,
    description: values.description,
    active: values.active,
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    // TODO ::: API CALL
    const res = await axios.patch(
      `${BASE_URL}/api/feature/${id}`,
      JSON.stringify(formData),
      config
    );
    dispatch({
      type: UPDATE_FEATURE,
    });

    dispatch(getFeatureList());
    toast.success("Link updated successfully");
    return true;
  } catch (err) {
    toast.error(err.response.data.message);
    return false;
  }
};

//DELETE Feature
export const deleteFeature = (id) => async (dispatch) => {
  try {
    await axios.delete(`${BASE_URL}/api/feature/${id}`);
    dispatch({
      type: DELETE_FEATURE,
      payload: id,
    });
    return true;
  } catch (err) {
    toast.error(err.response.data.message);
    return false;
  }
};
