export const getSubName = (grades, subjectId) => {
  console.log(subjectId);
  if (grades && grades.length > 0) {
    let subName = "";
    grades.map((grade) => {
      grade.subjects.map((item) => {
        if (item.id === subjectId) {
          subName = item.name;
        }
      });
    });

    return subName;
  } else {
    return "";
  }
};
