import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../constants/URL";

//GET MARK LIST
export const getMark =
  (batchId, year, month, gradeId, shiftId, uid) => async (dispatch) => {
    let data = {
      batchId,
      year,
      month,
      gradeId,
      shiftId,
      uid,
    };

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post(
        `${BASE_URL}/api/ev/get`,
        JSON.stringify(data),
        config
      );

      return {
        ...res.data.data,
        success: res.data.success,
        student: { ...res.data.student },
      };
    } catch (err) {
      toast.error(err.response.data.message);
      return false;
    }
  };

// CREATE MARK
export const createMark =
  (month, year, batchId, subjects, grade, shift, uid, remark) =>
  async (dispatch) => {
    let formData = {
      month,
      year,
      batchId,
      marks: [],
      shiftId: shift,
      gradeId: grade,
      uid,
      overall: remark,
    };

    for (let i = 0; i < subjects.length; i++) {
      formData.marks.push({
        subjectId: subjects[i].subjectId,
        grade: subjects[i].grade,
      });
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      // TODO ::: API CALL
      const res = await axios.post(
        `${BASE_URL}/api/ev`,
        JSON.stringify(formData),
        config
      );
      console.log(res.data);
      toast.success(res.data.message);

      return true;
    } catch (err) {
      toast.error(err.response.data.message);
      return false;
    }
  };
