import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import {
  AiOutlineEdit,
  AiOutlinePrinter,
  AiOutlineStar,
  AiOutlineUser,
} from "react-icons/ai";
import { BiUserPin } from "react-icons/bi";
import {
  BsFillDiagram3Fill,
  BsFillFileEarmarkBarGraphFill,
  BsGraphUp,
} from "react-icons/bs";
import { HiOutlineUserGroup } from "react-icons/hi";
import {
  MdAddChart,
  MdHotelClass,
  MdOutlineCompareArrows,
  MdOutlineSdCardAlert,
} from "react-icons/md";
import { SiGoogleclassroom } from "react-icons/si";
import { connect } from "react-redux";
import { getDashboardData } from "../../actions/Dashboard.action";
import Layout from "../../components/shared/Layout/Layout";
import StatCard from "../../components/shared/StatCard/StatCard";

const DashboardPage = ({ data, getDashboardData }) => {
  useEffect(() => {
    if (!data) {
      getDashboardData();
    }
  }, []);
  return (
    <Layout>
      {data ? (
        <Row className="pt-0">
          <Col md={3} className="py-3">
            <StatCard
              title="Total Student"
              icon={<AiOutlineUser />}
              count={data.student}
            />
          </Col>
          <Col md={3} className="py-3">
            <StatCard
              title="Total Class"
              icon={<BsFillDiagram3Fill />}
              count={data.grade}
            />
          </Col>

          <Col md={3} className="py-3">
            <StatCard
              title="Total Batch"
              icon={<HiOutlineUserGroup />}
              count={data.batch}
            />
          </Col>

          <Col md={12} className="py-3">
            <span className="d-block fs-4 border-bottom pt-4">Quick Links</span>
          </Col>

          {/*<Col md={3} className="py-3">
            <StatCard
              title="Class Summary"
              icon={<MdHotelClass />}
              link="/admin/class-summary"
            />
      </Col>*/}
        </Row>
      ) : (
        <></>
      )}
    </Layout>
  );
};
const mapStateToProps = (state) => ({
  data: state.auth.dashboard,
});
export default connect(mapStateToProps, { getDashboardData })(DashboardPage);
