import React from "react";
import Layout from "../../components/shared/Layout/Layout";
import { FeatureList } from "../../components/FeatureList";

const FeatureListPage = () => {
  return (
    <div
      className="bg_dark"
      style={{ minHeight: "100vh", position: "relative" }}
    >
      <Layout title="Landing Links">
        <FeatureList />
      </Layout>
    </div>
  );
};

export default FeatureListPage;
