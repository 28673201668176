import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { getGradeList } from "../../actions/Grade.action";
import ViewMarkForm from "../../components/ViewMarkForm/ViewMarkForm";
import PublicLayout from "../../components/shared/PublicLayout/PublicLayout";

const PublicMarkView = ({ edit, grades, getGradeList }) => {
  const { id } = useParams();
  useEffect(() => {
    getGradeList();
  }, []);
  return (
    <div className="bg_light">
      <PublicLayout title={`Evaluation`}>
        {grades ? <ViewMarkForm grades={grades} /> : <Spinner />}
      </PublicLayout>
    </div>
  );
};

const mapStateToProps = (state) => ({
  grades: state.grade.grade,
});

export default connect(mapStateToProps, { getGradeList })(PublicMarkView);
