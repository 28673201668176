import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import FeatureItem from "./FeatureItem/FeatureItem";
import styles from "./Features.module.css";
import { connect } from "react-redux";
import { getFeatureList } from "../../actions/Feature.action";
import { images } from "./stub/fakeData";

const Features = ({ data, getFeatureList }) => {
  useEffect(() => {
    if (!data) {
      getFeatureList();
    }
  }, []);

  if (!data || (data && data.filter((item) => item.active).length === 0)) {
    return <></>;
  }
  return (
    <div className={styles.features} data-aos="fade-top">
      <Container>
        <span className="d-block heading__2 text-center">Result Section</span>
        {/* <span className="d-block lead__1 text-center">
          We love making your life easy by providing useful and effective
          features
        </span> */}
        <Row className="py-5">
          {data
            .filter((item) => item.active)
            .map((feature, i) => (
              <FeatureItem
                title={feature.name}
                description={feature.description}
                image={images[i % 6]}
                link={feature.url}
                key={feature.id}
              />
            ))}
        </Row>
      </Container>
    </div>
  );
};
const mapStateToProps = (state) => ({
  data: state.feature.feature,
});
export default connect(mapStateToProps, { getFeatureList })(Features);
