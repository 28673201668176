export const remarkArray = [
  {
    name: "A",
    text: "Outstanding Achievment",
  },
  {
    name: "B",
    text: "Satisfactory Achievment",
  },
  {
    name: "C",
    text: "Improvement Needed",
  },
  {
    name: "D",
    text: "Good Effort",
  },
];
