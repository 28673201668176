import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScaleLoader from "react-spinners/ScaleLoader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import PrivateOutlet from "./utils/PrivateOutlet";
import setAuthToken from "./utils/setAuthToken";
import {
  AddMarkPage,
  AddSubjectPage,
  DashboardPage,
  LoginPage,
  SubjectListPage,
} from "./views";
import AddBatchPage from "./views/AddBatchPage/AddBatchPage";
import AddGradePage from "./views/AddGradePage/AddGradePage";
import AddShiftPage from "./views/AddShiftPage/AddShiftPage";
import AddStudentPage from "./views/AddStudentPage/AddStudentPage";
import BatchListPage from "./views/BatchListPage/BatchListPage";
import GradeListPage from "./views/GradeListPage/GradeListPage";
import LandingPage from "./views/LandingPage/LandingPage";
import ShiftListPage from "./views/ShiftListPage/ShiftListPage";
import StudentListPage from "./views/StudentListPage/StudentListPage";
import PublicMarkView from "./views/PublicMarkView/PublicMarkView";
import AddFeaturePage from "./views/AddFeaturePage/AddFeaturePage";
import FeatureListPage from "./views/FeatureListPage/FeatureListPage";

function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (localStorage.getItem("token_coaching")) {
      setAuthToken(localStorage.getItem("token_coaching"));
    }
    loadingHandeler();

    return () => {
      setLoading(false);
    };
  }, [loading]);

  const loadingHandeler = () => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  };
  return (
    <>
      {loading ? (
        <div className={`landing_loader`}>
          <span className="d-block text-light fs-4 py-4">Loading...</span>
          <ScaleLoader color={"#f68a3c"} loading={loading} size={150} />
        </div>
      ) : (
        <></>
      )}
      <ToastContainer newestOnTop theme="dark" />
      <BrowserRouter>
        <Routes>
          {/* PUBLIC ROUTES */}
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/evaluation" element={<PublicMarkView />} />

          <Route path="/*" element={<PrivateOutlet />}>
            <>
              {/* PRIVATE ROUTES */}
              <Route path="dashboard" element={<DashboardPage />} />
              <Route path="grade/add" element={<AddGradePage />} />

              <Route
                path="grade/:id/edit"
                element={<AddGradePage edit={true} />}
              />
              <Route path="grade" element={<GradeListPage />} />
              <Route path="admin/shift" element={<ShiftListPage />} />
              <Route path="admin/shift/add" element={<AddShiftPage />} />
              <Route
                path="admin/links/:id/edit"
                element={<AddFeaturePage edit={true} />}
              />
              <Route path="admin/links/add" element={<AddFeaturePage />} />
              <Route path="admin/links" element={<FeatureListPage />} />
              <Route
                path="admin/shift/:id/edit"
                element={<AddShiftPage edit={true} />}
              />
              <Route path="admin/batch" element={<BatchListPage />} />
              <Route path="admin/batch/add" element={<AddBatchPage />} />
              <Route
                path="admin/batch/:id/edit"
                element={<AddBatchPage edit={true} />}
              />
              <Route path="admin/student" element={<StudentListPage />} />
              <Route path="admin/student/add" element={<AddStudentPage />} />
              <Route
                path="admin/student/:id/edit"
                element={<AddStudentPage edit={true} />}
              />
              <Route path="admin/evaluation" element={<AddMarkPage />} />
              <Route path="admin/subject" element={<SubjectListPage />} />
              <Route path="admin/subject/add" element={<AddSubjectPage />} />
              <Route
                path="admin/subject/:id/edit"
                element={<AddSubjectPage edit={true} />}
              />
            </>
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
