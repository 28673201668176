import {
  DELETE_FEATURE,
  GET_FEATURE,
  GET_FEATURE_DETAILS,
} from "../constants/Type";

const initialState = {
  feature: null,
  selected_feature: null,
  loading: true,
};

const featureReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_FEATURE:
      return {
        ...state,
        feature: payload,
        loading: false,
      };

    case GET_FEATURE_DETAILS:
      return {
        ...state,
        selected_feature: payload,
        loading: false,
      };

    case DELETE_FEATURE:
      return {
        ...state,
        feature: state.feature.filter((grd) => grd.id !== parseInt(payload)),
        loading: false,
      };

    default:
      return state;
  }
};

export default featureReducer;
