import { combineReducers } from "redux";
import authReducer from "./Auth.reducer";
import batchReducer from "./Batch.reducer";
import gradeReducer from "./Grade.reducer";
import shiftReducer from "./Shift.reducer";
import studentReducer from "./Student.reducer";
import subjectReducer from "./Subject.reducer";
import featureReducer from "./Feature.reducer";

const reducer = combineReducers({
  auth: authReducer,
  grade: gradeReducer,
  shift: shiftReducer,
  batch: batchReducer,
  student: studentReducer,
  subject: subjectReducer,
  feature: featureReducer,
});

export default reducer;
