import workflowImg from "../../../Assets/Features/Workflow.svg";
import flexibilityImg from "../../../Assets/Features/Flexibility.svg";
import userImg from "../../../Assets/Features/User.svg";
import layoutImg from "../../../Assets/Features/Layout.svg";
import controlImg from "../../../Assets/Features/Control.svg";
import organizedImg from "../../../Assets/Features/Organized.svg";

const data = [
  {
    id: 5,
    title: "View Individual Result",
    description: "Watch your monthly individual result",
    image: controlImg,
    link: "/individual-result",
  },
  {
    id: 6,
    title: "View Subject-wise Result",
    description: "Watch your monthly subject wise result",
    image: organizedImg,
    link: "/view-result",
  },
  {
    id: 1,
    title: "Monthly Top result",
    description: "View the monthly top result",
    image: workflowImg,
    link: "/top-result",
  },
  {
    id: 4,
    title: "Monthly Serial Result",
    description: "Watch monthly basis serial result",
    image: userImg,
    link: "/monthly-form",
  },
  {
    id: 2,
    title: "Semister Result",
    description: "See semister result of individual student",
    link: "/semister",
    image: flexibilityImg,
  },
  {
    id: 3,
    title: "Top Semister Result",
    description: "See the top semister results.",
    link: "/semister-top",
    image: layoutImg,
  },
  {
    id: 8,
    title: "Final Result",
    description:
      "See the final result ( combination of 1st, 2nd and 3rd semester result)",
    link: "/full-semister",
    image: layoutImg,
  },
  {
    id: 8,
    title: "Final Merit List",
    description:
      "See the final merit list ( combination of 1st, 2nd and 3rd semester result)",
    link: "/merit-list",
    image: workflowImg,
  },
];

export const images = [
  controlImg,
  organizedImg,
  workflowImg,
  userImg,
  flexibilityImg,
  layoutImg,
];

export default data;
